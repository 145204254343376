.emojis {
  padding-top: 0.9rem;
  text-align: center;
  font-size: 3.5rem;
  line-height: 3.1rem;
  overflow: hidden;
}

.emoji-container {
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
} .emoji-container:hover {
  background: #666;
  border-radius: 5px;
}

.emoji {
  display: block;
  position: relative;
  top: 0.55rem;
  font-family: apple color emoji, segoe ui emoji, notocoloremoji, segoe ui symbol, android emoji, emojisymbols, emojione mozilla;
}

.no-results {
  font-size: 30px;
  font-family: monospace;
  color: #666;
  text-align: center;
}