@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  src: local('Asap Regular'), local('Asap-Regular'), url(https://fonts.gstatic.com/s/asap/v7/KFOoCniXp96ayzse4GZNCzc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.Search {
  padding: 0.3em 0.15em;
}

.Search input {
  width: 100%;
  border: none;
  font-size: 1.7em !important;
  padding: 0.8em !important;
  box-sizing: border-box;
  background-color: #eee;
  border-radius: 0.5em;
  color: #333;
  font-family: 'Asap', sans-serif !important;
  text-align: center;
}

@media screen and (max-width: 22rem) {
  .Search input {
    font-size: 1.4em !important;
  }
}