.App {
  text-align: left;
  margin: 0 auto;
  padding: 0rem 1rem;
}

@media screen and (min-width: 19.5rem) {
  .App {
    width: 17.5rem;
  }
}

@media screen and (min-width: 23rem) {
  .App {
    width: 21rem;
  }
}

@media screen and (min-width: 26.5rem) {
  .App {
    width: 24.5rem;
  }
}

@media screen and (min-width: 30rem) {
  .App {
    width: 28rem;
  }
}

@media screen and (min-width: 33.5rem) {
  .App {
    width: 31.5rem;
  }
}

@media screen and (min-width: 37rem) {
  .App {
    width: 35rem;
  }
}

@media screen and (min-width: 40.5rem) {
  .App {
    width: 38.5rem;
  }
}

@media screen and (min-width: 44rem) {
  .App {
    width: 42rem;
  }
}

@media screen and (min-width: 47.5rem) {
  .App {
    width: 45.5rem;
  }
}

@media screen and (min-width: 51rem) {
  .App {
    width: 49rem;
  }
}