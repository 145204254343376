@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(/public/fonts/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.Header {
  padding: 0.2em 0.15em;
}

.Header h1 {
  font-size: 2.4rem;
  color: #555;
  text-align: center;
  font-family: 'Pacifico', cursive;
}
