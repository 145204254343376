.Footer {
  padding: 2em 0.15em 3em;
  color: #666;
}

a {
  color: #666;
  font-weight: bold;
  text-decoration: none;
}

.togglable-content {
  background: #eee;
  color: #000;
  margin: 0.5em 0em;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  line-height: 1.4em;
} .togglable-content p {
  margin: 0.5em 0em; 
} .togglable-content a {
  color: #000;
  text-decoration: underline;
}

button {
  border: none;
  padding: 0em;
  margin: 0em;
  font-size: inherit;
  background: inherit;
  cursor: pointer;
  color: inherit;
  font-weight: bold;
}

.question {
  font-weight: bold;
}